
/* Variables */

:root {

  /* Reset 1rem = var(--base-padding) */
  font-size: 10px;

  /* Basic colors */
  --white:#ffffff;
  --black:#000000;
  --body-background: #f2f2f2;
  /* Project colors */
  --easy-black: #2a2d36;
  --light-black: #263238;
  --light-color: #d1d0d5;
  --white-gray: #f3f3f3;
  --dark-color: #5d586c;
  --primary-color: #595664;
  --grey-blue: #85919d;
  --semi-grey: #858585;
  --white-blue: #e5e9ea;
  --classic-grey: #eee;
  --darker-green: #539447;
  --dark-alarm: #a62828;
  --light-native: #c9b790;
  --native: #c5ae87;

  --speed: 0.5s;

  /* Layout variables */
  --content-width: 1200px;
  --base-padding: 1rem;
  --big-padding: 2rem;
  --semi-size: 0.8rem;
  --mini-size: 0.6rem;

  --header-height: 16rem;
  --keyvisual-height: 60rem;

  --border-radius: 0.5rem;
}

/* Reset */

*,*::before,*::after {
  box-sizing:border-box;
}

html,body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,
pre,form,fieldset,input,textarea,p,blockquote,th,td,
header,footer,aside,nav,article,figure,figcaption {
  margin:0;
  padding:0;
  box-sizing: content-box;
}

fieldset,img {
  border:0;
}
address,caption,cite,code,dfn,em,strong,th,var {
  font-style:normal;
  font-weight:400;
}
ol,ul {
  list-style:none;
}
caption,th {
  text-align:left;
}
h1,h2,h3,h4,h5,h6 {
  font-size:100%;
}
q:before,q:after {
  content:'';
}
abbr,acronym {
  border:0;
}
a {
  text-decoration:none;
}

a:active, a:focus {
  outline:none;
}

/* Globals */

@font-face {
  font-family: 'FontAwesome';
  src: url('./fonts/fa/fa-light-300.ttf');
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: url('./fonts/roboto/Roboto-300.woff2') format('woff2');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'), url('./fonts/roboto/Fl4y0QdOxyyTHEGMXX8kcYX0hVgzZQUfRDuZrPvH3D8.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* cyrillic */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('./fonts/roboto/uYECMKoHcO9x1wdmbyHIm3-_kf6ByYO6CLYdB4HQE-Y.woff2') format('woff2');
  unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}

/* latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'), url('./fonts/roboto/oMMgfZMQthOryQo9n22dcuvvDin1pK8aKteLpeZ5c0A.woff2') format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2212, U+2215, U+E0FF, U+EFFD, U+F000;
}

body {
  font-family: 'Roboto', sans-serif;
  font-size: 2rem;
  font-weight: 200;
  line-height:1.4;
  background: var(--body-background);
  overflow-y:scroll;
}

section {
  padding:var(--big-padding);
}

p,ul,figure {
  margin-bottom:3rem;
}

h1,h2,h3,h4,h5,h6 {
  line-height:1;
}

h1 {
  font-size:4rem;
  margin-bottom:3rem;
}

h2 {
  font-weight: 300;
  font-size: 1.6rem;
}

h3 {
  font-size:2.5rem;
  margin-bottom:1.5rem;
}

h4 {
  font-size:2.2rem;
  font-weight:700;
}

body, p {
  font-size: 1.5rem;
  font-family: 'Roboto', sans-serif;
  font-weight: 100;
}

p, label {
  font-size: 1.4rem;
  padding-bottom: 0;
  margin: 2rem 0;
  /*line-height: 0;*/
}

.mini, label {
  font-size: 1.2rem;
}

label {
  /*font-size: 0.9rem;*/
  /*text-transform: uppercase;*/
  /*color: var(--grey-blue);*/
}

.mini.gray {
  font-size: 1.4rem;
  color: var(--grey-blue);
}

*,*:focus,*:hover{
  outline:none;
  transition: 0.05s ease;
}

a {
  transition: var(--speed);
  color: var(--primary-color);
}

a:hover, .feather:hover, .title li.active {
  color: var(--light-black)
}

.header {
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  background: var(--white);
  /*border-bottom: 1px solid #ededed;*/
  height: content-box;
}

.container {
  /*padding-top: 0;*/
  padding-bottom: 0;
}

.header.full_version {
  position: absolute;
  /*height: var(--header-height);*/
}

.header.mini_version {
  position: fixed;
  background-color:rgba(255,255,255,0.8);
  padding: 0;
  margin: 0;
}

.header.full_version header {
  display: block;
}

.header.mini_version header {
  display: none;
}

#root {
  /*display: flex;*/
  /*flex-direction: column;*/
  /*justify-content: flex-start;*/
}

.main, .container {
  position: relative;
  margin: 0 auto;
}

.main {
  /*min-height: 100vh;*/
}

.container {
  max-width: var(--content-width);
  padding: var(--big-padding);
}

.container img {
  width: 100%;
}

.container.no-padding {
  padding-top: 0;
  padding-right: 0;
  padding-left: 0;
}

.container.no-top {
  padding-top: 0;
}

.no-bottom {
  padding-bottom: 0;
}

.main {
  position: relative;
  /*margin-top: calc(var(--header-height) + 3rem);*/
  margin-top: 20rem;
  margin-bottom: 5rem;
  /*background: #fff;*/
  /*min-height: 1400px;*/
}

.mobile-only {
  display: none
}

.clear {
  clear: both;
}

.top_navigation {
  width: 100%;
}

.top_navigation li {
  padding-right: var(--base-padding);
}

.top_navigation li:last-child {
  float: right;
  padding-right: 0;
}

.logo {
  position: relative;
  top: -2rem;
  margin: 0 auto;
  width: 40rem;
  clear: both;
}

.logo img {
  width: 100%;
}

ul:not(.items-list) {
  list-style: none;
}

li {
  display: inline-block;
}

.main_menu {
  position: relative;
  margin: 0 auto;
}

.main_menu, .main_menu ul, .main_menu li {
  margin-bottom: 0;
}

.main_menu li a {
  color: var(--grey-blue);
  transition: var(--speed);
}

.main_menu li a.active, .main_menu li a:hover {
  color: var(--black);
}

img {
  border-radius: var(--border-radius);
}
div.title-image img {
  border-radius: 0;
}

.feather {
  /*color: var(--primary-color);*/
  /*color: blue;*/
  opacity: 0.7;
  transition: var(--speed);
  cursor: pointer;
  width: 1.8rem;
  height: 1.8rem;
  stroke: currentColor;
  stroke-width: 1.2;
  stroke-linecap: round;
  stroke-linejoin: round;
  fill: none;
}

.feather.feather-loader {
  animation: rotation 7s infinite linear;
}

.feather.insta-icon {
  cursor: auto;
  width: 2.8rem;
  height: 2.8rem;
  margin-bottom: 0.5rem;
  color: var(--semi-grey)
}

.list {
  border-radius: 1rem;
  background: #fff;
  padding: 3rem;
  border: 1px solid var(--white-blue);
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor {
  cursor: pointer;
  transition: var(--speed);
}

.cursor:hover {
  color: var(--black);
}
.Toastify__toast-body {
  font-weight: 100;
  /*text-transform: uppercase;*/
  font-size: 90%;
  min-width: 280px;
}

.Toastify__toast--error {
  background: var(--dark-alarm) !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 100 !important;
  font-size: 1.5rem !important;
  padding-left: 15px !important;
}

.Toastify__toast--success {
  background: var(--darker-green) !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 100 !important;
  font-size: 1.5rem !important;
  padding-left: 15px !important;
}

.top_navigation .feather, .main_menu .feather {
  color: var(--semi-grey);
}

.top_navigation .feather:hover, .main_menu .feather:hover {
  color: var(--light-black);
}

.main_menu .feather {
  width: 2.8rem;
  height: 2.8rem;
  display: none;
}

.main_menu ul, .horizontal_menu ul {
  text-align: center;
  opacity: 1;
  visibility: visible;
  transition: opacity var(--speed) ease;
  height: auto;
}

.horizontal_menu {
  display: inline;
  clear: both;
}

.horizontal_menu ul {
  text-align: left;
  margin-bottom: 1rem;
  display: flex;
}

.horizontal_menu li {
  /*padding-right: 1rem;*/
  display:flex;
}

.horizontal_menu a {
  transition: var(--speed);
  font-weight: 400;
  color: var(--grey-blue);
  font-size: 1.4rem;
  padding-bottom: 1rem;
}

.horizontal_menu a:hover, .horizontal_menu a.active {
  color: var(--light-black);
}

.horizontal_menu a.active {
  border-bottom: 1px solid var(--semi-grey);
}

.main_menu li, .title li,.title-line li, .horizontal_menu li {
  font-size: 1.5rem;
  padding-right: 2rem;
}

.title li, .title-line li {
  position: relative;
}

.main_menu li::before, .title li::before, .horizontal_menu li::before {
  content: ' \B7 ';
  padding-right: 2rem;
}

.horizontal_menu li, .horizontal_menu li::before {
  padding-right: 1.3rem;
}

.info {
  padding: 0 0 0.4rem;
  font-size: 1.4rem;
  position: relative;
  display: block;
  font-weight: 100;
}

.info.bolder {
  font-size: 1.4rem;
}

.bolder {
  color: var(--light-black);
  font-weight: 400;
  /*font-size: 1.2rem;*/
  /*text-transform: uppercase;*/
}

.title li:not(.dropdown-menu), .title li::before{
  padding-right: 1rem;
}

.title li a, .title-line li a {
  transition: var(--speed);
  color: var(--semi-grey);
}

.title li a:hover, .title-line li a:hover {
  color: var(--light-black);
}

.main_menu li:first-child::before,
.horizontal_menu li:first-child::before,
.title li:first-child::before,
.title li.right-align::before,
.title li:last-child::before {
  content: none;
}

.main_menu li:first-child,
.horizontal_menu li:first-child
{
  padding-left: 0;
}
.main_menu li:last-child,
.horizontal_menu li:last-child
{
  padding-right: 0;
}

.title li:last-child {
  position: absolute;
  right: 0;
  padding: 0;
}

.title li:last-child:hover .feather {
  color: var(--light-black);
}

.title li .feather, .title-line li .feather {
  margin-bottom: -0.2rem;
}

.language {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
}

.language img {
  border-radius: 50%;
}

.language_menu {
  position: relative;
}

.language_menu ul {
}

.language_menu li {
  padding-left: 0;
}

.language_menu img {
  width: var(--big-padding);
  position: absolute;
  left: var(--base-padding);
  margin-right: var(--big-padding);
}

.shadow {
  box-shadow: 0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.005);
}

.dropdown-menu {
  position: absolute;
  top: 5rem;
  right: 2rem;
  background: #fff;
  border-radius: var(--border-radius);
  padding: var(--base-padding);
  width: 16rem;
  z-index: 3;
}

.dropdown-menu h3 {
  text-align: center;
  font-size: 63%;
  text-transform: uppercase;
  font-weight: 400;
}

.dropdown-menu ul {
  margin: 0;
  padding: var(--base-padding) 0 0;
  border-top: 1px solid var(--white-blue);
}

.dropdown-menu ul li {
  width: 100%;
  box-sizing: border-box;
  list-style-type: none;
  position: relative;
  padding: var(--base-padding) var(--base-padding) var(--base-padding) 4.5rem;
  margin-bottom: 0.5rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  transition: var(--speed);
  font-size: 1.4rem;
}

.dropdown-menu ul li:last-child {
  margin-bottom: 0;
}

.dropdown-menu ul li a, .dropdown-menu ul li span {
  color: var(--dark-color);
  font-size: 0.9rem;
  font-weight: 300;
}

.dropdown-menu ul li:hover, .dropdown-menu ul li.active {
  background: var(--white-gray);
}

.dropdown-menu ul li:hover a, .dropdown-menu ul li:hover .feather {
  opacity: 1;
  cursor: pointer;
}

.dropdownItem {
  display: flex;
  margin: var(--base-padding) auto;
}

.dropdownItem a {
  max-width: 10rem;
  margin-left: var(--base-padding);
  transition: var(--speed);
}

.block_active {
  display: block;
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  transition: var(--speed) ease;
  z-index: 1;
}

.block_inactive {
  opacity: 0;
  visibility: hidden;
  transform: translateY(var(--big-padding));
  transition: var(--speed) ease;
  z-index: 0;
}

.title, .mini-title, .title-line {
  position: relative;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: 1.6rem;
}

.mini-title {
  font-size: 1.4rem;
  margin-bottom: 0.2rem;
}

.title-mini-gray {
  font-size: 1.3rem;
  font-weight: 100;
  color: var(--light-color);
}

.title ul {
  border-bottom: 1px solid var(--light-color);
  padding-bottom: 1rem;
}

.right-align {
  position: relative!important;
  float: right!important;
}

.gallery-photo {
  position: relative;
  z-index: 1;
  cursor: pointer;
  transition: var(--speed);
}

.gallery-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: var(--speed);
  border-radius: 0.5rem;
}

.image-gallery {
  width: 100%;
  height: auto;
  position: relative;
}

.image-gallery-thumbnails button {
  border: 0;
}

.image-gallery-thumbnails button img {
  border: 1px solid var(--body-background);
  transition: var(--speed);
  opacity: 0.9;
}

.image-gallery-thumbnails button.active,
.image-gallery-thumbnails button:hover
{
  border: 0;
}

.image-gallery-thumbnails button.active img,
.image-gallery-thumbnails button:hover img {
  border: 1px solid var(--light-color);
  opacity: 1;

}

.image-gallery-slide img {
  width: 100%;
}
[data-rmiz] {
  z-index: 1;
}

[data-rmiz-ghost] {
  transition: var(--speed);
}

.gallery-photo:hover [data-rmiz-ghost] {
  /*z-index: 1;*/
  -moz-box-shadow: inset 0 -150px 150px -150px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 -150px 150px -150px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 -150px 150px -150px rgba(0, 0, 0, 0.5);
}

.gallery-photo:hover .gallery-overlay, .gallery-overlay.active {
  -moz-box-shadow: inset 0 -150px 150px -150px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: inset 0 -150px 150px -150px rgba(0, 0, 0, 0.5);
  box-shadow: inset 0 -150px 150px -150px rgba(0, 0, 0, 0.5);
}

.gallery-photo .feather {
  z-index: 5;
  position: absolute;
  color: var(--white);
  bottom: 2rem;
  right: 2rem;
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  stroke-width: 2;
  opacity: 0.5;
  /*visibility: hidden;*/
  -webkit-transition: var(--speed) ease;
  -moz-transition: var(--speed) ease;
  -ms-transition: var(--speed) ease;
  -o-transition: var(--speed) ease;
  transition: var(--speed) ease;
}

.gallery-photo .feather.feather-download {
  right: 6rem;
}

.gallery-photo .feather.feather-download.f-right {
  right: 2rem;
}

.gallery-photo:hover .feather, .gallery-photo .feather.feather-heart.active {
  display: block;
  opacity: 0.7;
  visibility: visible;
  transition: var(--speed) ease;
  z-index: 1;
}

.gallery-photo .feather.feather-heart.loading, .feather.loading {
  transform: scale(1);
  animation: pulse 2s infinite;
}

.input_choice {
  color: var(--light-black);
  cursor: pointer;
  transition: var(--speed);
}

.title li.input_choice .feather, .title-line li.input_choice .feather {
  margin-bottom: -0.5rem;
  color: var(--light-black);
}

.title li.input_choice:hover,
.title li.input_choice:hover .feather,
.title-line li.input_choice:hover,
.title-line li.input_choice:hover .feather
{
  color: var(--black);
}

.gallery-navigation {
  width: max-content;
  position: relative;
  display: inline-block;
  top: -3rem;
  /*margin-bottom: 2rem;*/
}

.gallery-navigation h3, .images-box h3 {
  text-align: center;
  color: #283252;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  padding: 0;
  margin-bottom: 0.5rem;
}

.gallery-navigation div:not(.stick) {
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 7rem;
  padding: 0 2rem;
  color: var(--grey-blue);
  font-size: 1rem;
  text-align: center;
  text-transform: uppercase;
}

.stick {
  position: absolute;
  top: 1rem;
  right: 0;
  width: 1px;
  height: 3rem;
  border-left: 1px solid var(--light-color);
}

.gallery-navigation div:last-child .stick {
  display: none;
}

.accept-selection {
  width: max-content;
  color: var(--grey-blue);
  font-size: 1.4rem;
  margin-top: -1rem;
  margin-bottom: 3rem;
  padding: 0.5rem 1.5rem;
  border-radius: var(--border-radius);
  border: 1px solid var(--white-blue);
}

.accept-selection:hover {
  color: var(--light-black);
  /*border: 1px solid #758a8d;*/
  border: 1px solid #bdbdbd;
  /*border: 1px solid var(--classic-grey);*/
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
  }

  50% {
    transform: scale(1.1);
  }

  100% {
    transform: scale(0.9);
  }
}

.gallery-photo:hover .feather:hover, .gallery-photo .feather.feather-heart.active {
  opacity: 1;
}

.gallery-photo .feather.feather-heart.active {
  fill: var(--white);
}

.title li .dropdown-menu, .title-line li .dropdown-menu {
  top: 3rem;
  left: 0;
  z-index: 2;
  padding: 1rem;
}
.dropdown-menu li::before {
  content: none;
}

.title li .dropdown-menu ul, .title-line li .dropdown-menu ul {
  display: block;
  border: 0;
  padding: 0;
}
.title li .dropdown-menu ul li, .title-line li .dropdown-menu ul li {
  display: block;
  list-style: none;
  padding: 1rem;
}

.title li .dropdown-menu ul li a, .title-line li .dropdown-menu ul li a {
  font-size: 1.5rem;
}

.title li .dropdown-menu ul li:last-child, .title-line li .dropdown-menu ul li:last-child {
  position: relative;
}


.back-to-top {
  position: fixed;
  opacity: 50%;
  z-index: 2;
  bottom: 50px;
  right: 30px;
  cursor: pointer;
  transition: var(--speed);
}

.back-to-top .feather {
  width: 32px;
  height: 32px;
  transition: var(--speed);
  color: var(--grey-blue);
}

.back-to-top:hover {
  opacity: 100%;
}

.back-to-top:hover .feather {
  color: var(--primary-color);
}

.hide {
  display: none;
  z-index: 0;
}

.show {
  display: block;
}

.alarm {
  position: relative;
  font-size: 1.2rem;
  color: var(--dark-alarm);
  padding-left: 2rem;
}

.alarm .feather {
  position: absolute;
  top: 0;
  left: 0;
  width: 1.5rem;
  height: 1.5rem;
}

.center-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: calc(100vh - 280px);
}

@keyframes ldio-rpinwye8j0b {
  0% { transform: rotate(0deg) }
  50% { transform: rotate(180deg) }
  100% { transform: rotate(360deg) }
}

.ldio-rpinwye8j0b div {
  position: absolute;
  animation: ldio-rpinwye8j0b 1s linear infinite;
  width: 80px;
  height: 80px;
  top: 20px;
  left: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 0 0 var(--darker-green);
  transform-origin: 40px 42px;
}
.loadingio-eclipse {
  width: 110px;
  height: 110px;
  display: inline-block;
  overflow: hidden;
}
.ldio-rpinwye8j0b {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-rpinwye8j0b div {
  box-sizing: content-box;
}

.layer-number {
  z-index: 2;
  position: absolute;
  top: 15px;
  right: 25px;
  font-size: 80px;
  opacity: 30%;
  color: #999999;
}

.parent {
  position: relative;
}

.custom_select {
  width: 50%;
}

.select_arrow {
  position: relative;
  /*font-weight: 500;*/
  border: 1px solid var(--white-gray);
  background: #fff;
  padding: 1rem;
  font-size: 1.4rem;
  cursor: pointer;
  transform: var(--speed);
  border-radius: var(--border-radius);
}

.select_arrow .feather {
  position: absolute;
  right: 1rem;
  top: 1rem;
  /*margin-bottom: -0.5rem;*/
  /*margin-left: 0.5rem;*/
  stroke-width: 2.2;
  color: var(--black);
  /*padding-top: 175px;*/
  /*width: 60px;*/
  /*height: 60px;*/
}

.gallery-archive {
  color: var(--primary-color);
  cursor: pointer;
  transition: var(--speed);
}

.gallery-archive .feather {
  transition: none;
}

.gallery-archive:hover, .gallery-archive:hover .feather {
  color: var(--black);
}

.select_arrow:hover, .select_arrow:hover .feather {
  color: var(--black);
  /*background: var(--white-gray);*/
}

.select_arrow.is_active .feather {
  transform: rotate(180deg)
}

.options-menu {
  position: absolute;
  top: 5rem;
  left: 0;
  /*width: 100%;*/
  background: #fff;
  border-radius: var(--border-radius);
}

.member-courses-list.options-menu {
  top: 2rem;
  width: max-content;
}

.options-menu .options-content-block {
  position: relative;
  padding: 0.8rem 1rem;
  max-height: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.options-content-block .option {
  font-size: 1.3rem;
  cursor: pointer;
  border-radius: var(--border-radius);
  padding: 10px;
  margin-bottom: 3px;
  font-weight: 300;
}

.options-content-block .option.inactive {
  cursor: auto;
  color: var(--light-color);
}

.options-content-block .option:not(.inactive):hover, .options-content-block .option.active {
  background: var(--white-gray);
}

.options-content-block::-webkit-scrollbar, textarea::-webkit-scrollbar {
  width: 4px;
}

.options-content-block::-webkit-scrollbar-track
{
  background: #e3e3e3;
  border-radius: 10px;
}


input, input[type="button"], input[type="submit"], .button {
  box-sizing: border-box;
  transition: var(--speed);
}

input[type="button"], input[type="submit"], .button {
  background: none;
  cursor: pointer;
  text-align: center;
  padding: 1rem 3rem;
}

input[type="submit"]:not(.grey-submit), input[type="button"].btn-green, input[type="button"].btn-nature {
  margin: 1rem 0;
  background: #51a443;
  color: #fff;
  cursor: pointer;
  text-align: center;
}

input[type="button"].btn-nature {
  background: var(--light-native);
}


.input, input[type="text"], input[type="number"], input[type="date"], textarea {
  margin-bottom: 1rem;
}

textarea {
  width: 100%;
  box-sizing: content-box;
}

input[type="text"], input[type="number"], input[type="date"], textarea {
  width: 50%;
  display: block;
  padding: 1rem;
  transition: var(--speed);
  border: 0;
}

.mini-container {
  max-width: 768px;
  margin: 0 auto;
  font-size: 1.3rem;
  padding: 2rem 2rem 0;
}

.new-review {
  max-width: 768px;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 2rem;
  justify-content: space-between;
  grid-auto-rows: 1fr;
  transition: var(--speed);
  margin: 0 auto;
  padding: 0 2rem 2rem;
}

.new-review, .new-review .info {
  font-size: 1.3rem;
}

.new-review textarea {
  width: calc(100% - 2rem);
  resize: none;
}
/*input[type="date"]::-webkit-calendar-picker-indicator {*/
/*  background: transparent;*/
/*  bottom: 0;*/
/*  color: transparent;*/
/*  cursor: pointer;*/
/*  height: auto;*/
/*  left: 0;*/
/*  position: absolute;*/
/*  right: 0;*/
/*  top: 0;*/
/*  width: auto;*/
/*}*/

input[type="button"]:not(.bottom-border),
input[type="text"],
input[type="number"],
input[type="submit"],
input[type="date"],
textarea,
.button
{
  border: 1px solid var(--white-gray);
  border-radius: var(--border-radius);
}

input[type="text"]:checked,
input[type="text"]:active,
input[type="text"]:hover,
input[type="text"]:focus,
input[type="number"]:checked,
input[type="number"]:active,
input[type="number"]:hover,
input[type="number"]:focus,
input[type="date"]:checked,
input[type="date"]:active,
input[type="date"]:hover,
input[type="date"]:focus,
textarea:checked,
textarea:active,
textarea:hover,
textarea:focus,
input[type="button"]:checked,
input[type="button"]:active,
input[type="button"]:hover,
input[type="button"]:focus,
input[type="submit"].grey-submit:checked,
input[type="submit"].grey-submit:active,
input[type="submit"].grey-submit:hover,
input[type="submit"].grey-submit:focus,
.button:hover
{
  border: 1px solid var(--light-color);
}

input[type="date"],
input[type="date"]:checked,
input[type="date"]:active,
input[type="date"]:hover,
input[type="date"]:focus {
  background: var(--white);
}

input[type="date"] {
  position: relative;
  padding: 1rem;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  color: transparent;
  background: none;
  z-index: 1;
}

input[type="date"]:before {
  color: transparent;
  background: none;
  display: block;
  font-family: 'FontAwesome';
  content: '\f073';
  width: 15px;
  height: 20px;
  position: absolute;
  top: 12px;
  right: 0.8rem;
  color: #999;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: none !important;
}

input[type="date"], input[type="time"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.input_base input[type="date"]::-ms-clear {
  display: none;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.unstyled {
  -webkit-appearance: none;
}

/*.reviews input[type="button"]:checked,*/
/*.reviews input[type="button"]:active,*/
/*.reviews input[type="button"]:hover,*/
/*.reviews input[type="button"].active {*/
/*  color: var(--light-black);*/
/*  border: 0;*/
/*  border-bottom: 1px solid var(--white-gray);*/
/*  border-radius: 0;*/
/*}*/

input[type="button"].bottom-border {
  padding: 0 0 1.5rem;
}
input[type="button"].bottom-border,
input[type="button"].bottom-border:focus,
input[type="button"].bottom-border:checked,
input[type="button"].bottom-border:active,
input[type="button"].bottom-border:hover
{
  color: var(--grey-blue);
  border: 0;
  border-bottom: 1px solid var(--white-gray);
  border-radius: 0;
}

input[type="button"].bottom-border:checked,
input[type="button"].bottom-border:active,
input[type="button"].bottom-border:hover,
input[type="button"].bottom-border.active {
  color: var(--light-black);
  border-bottom: 1px solid var(--semi-grey);
}

input {
  font-family: 'Roboto';
}

input[type="submit"].grey-submit.mini-title {
  font-weight: 100;
  font-size: 1.2rem;
  /*text-transform: uppercase;*/
  color: var(--grey-blue)
  /*color: var(--semi-grey)*/
}

input[type="submit"].grey-submit.mini-title:hover {
  color: var(--primary-color)
}

input::placeholder, textarea::placeholder {
  font-family: Roboto;
  font-weight: 100;
  color: var(--light-color)
}

input[type="submit"]:not(.grey-submit):checked,
input[type="submit"]:not(.grey-submit):active,
input[type="submit"]:not(.grey-submit):hover,
input[type="submit"]:not(.grey-submit):focus,
input[type="button"].btn-green:checked,
input[type="button"].btn-green:active,
input[type="button"].btn-green:hover,
input[type="button"].btn-green:focus
{
  border: 1px solid var(--white);
  background: var(--darker-green);
}

input[type="button"].btn-nature:checked,
input[type="button"].btn-nature:active,
input[type="button"].btn-nature:hover,
input[type="button"].btn-nature:focus
{
  background: var(--native);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

/*Change text in autofill textbox*/
input:-webkit-autofill{
  -webkit-text-fill-color: var(--black) !important;
}

.phone input {
  padding-left: 3rem;
}

.phone .feather {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 1.5rem;
  height: 1.5rem;
}

.not-found {
  margin: 0 auto;
  text-align: center;
  max-width: 46rem;
  min-height: calc(100vh - 35rem);
}

.not-found h2 {
  opacity: 0.7;
  font-weight: bold;
  margin: 10rem auto 0;
  padding: 3rem 0;
  color: #d4d5da;
  text-align: center;
  font-size: 20rem;
}

.not-found h4 {
  margin: 0;
  padding: 0;
  color: #283252;
  font-weight: 500;
  font-size: 140%;
}

.not-found p {
  padding: 1rem 2rem;
  font-weight: 100;
  color: var(--grey-blue);
  line-height: 1.5;
}

input[type="button"].btn-green, input[type="button"].btn-nature {
  margin: 0;
}

.images-box, .price-list, .baby_data, .calc, .colors, .calculator-list, .wishes, .two-lists, .line-three, .home-box,
.instagram-box, .three-box {
  position: relative;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 2rem;
  justify-content: space-between;
  grid-auto-rows: 1fr;
  font-size: 1.5rem;
}

.instagram-box a:nth-child(4) {
  display: none;
}

.home-box {
  cursor: pointer;
}

.home-box div img {
  margin-bottom: 2rem;
}

.home-box .mini-title {
  padding-bottom: 0.5rem;
}

.line-three input {
  width: 100%;
}

.wishes input {
  width: 100%;
  margin-bottom: 0.5rem;
}

.calculator-list input[type='submit'] {
margin-top: 1rem;
}

.calculator-list, .wishes, .two-lists {
  grid-template-columns: 1fr 1fr;
}

.colors {
  grid-template-columns: repeat(12, 1fr);
  justify-items: center;
  align-items: center;
  grid-gap: 2rem 2rem;
}

.colors div {
  position: relative;
  background: var(--grey-blue);
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
  cursor: pointer;
  transition: var(--speed);
  /*margin-bottom: 1rem;*/
}

.colors div:first-child {
  background: #f1f1f1;
}

.colors div:nth-child(2) {
  background:#fbf5e9;
}

.colors div:nth-child(3) {
  background: #fad7dd;
}

.colors div:nth-child(4) {
  background: #d8d3f3;
}

.colors div:nth-child(5) {
  background: #cee3ee;
}

.colors div:nth-child(6) {
  background: #b2d7ab;
}

.colors div:nth-child(7) {
  background: #efe79b;
}

.colors div:nth-child(8) {
  background: #c49475;
}

.colors div:nth-child(9) {
  background: #b94f53;
}

.colors div:nth-child(10) {
  background: #598ea9;
}

.colors div:nth-child(11) {
  background: #509a44;
}

.colors div:nth-child(12) {
  background: #544577;
}

.colors2233 div:hover:before,
.colors div.active:before,
.calculator-banner:before,
.book-size:before,
.book-counter:before,
.book-layers:before
{
  font-family: FontAwesome;
  font-weight: 200;
  content: "\f00c";
  position: absolute;
  top: 1.5rem;
  left: 2rem;
}


.book-size:before,
td.book-counter:before,
td.book-layers:before
{
  content: "\e0c0";
  color: var(--light-color);
  font-size: 2.5rem;
  /*position: absolute;*/
  top: 0;
  text-align: center;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  /*left: 38%;*/
  /*display:flex;*/
  /*flex-direction:row;*/
  /*align-items: center;*/
  /*justify-content: center;*/
  /*display: flex;*/
  /*align-items: center;*/
  transition: var(--speed);
  opacity: 0.7;
}

.book-size:hover:before,
td.book-counter:hover:before,
td.book-layers:hover:before {
  color: var(--grey-blue);
}

.price-list .book-size .feather,
.price-list td.book-counter .feather,
.price-list td.book-layers .feather {
  width: 2rem;
  height: 2rem;
  cursor: pointer;
  color: #bdbdbd;
  /*color: var(--grey-blue)*/
}

.price-list .book-size:hover .feather,
.price-list td.book-counter:hover .feather,
.price-list td.book-layers:hover .feather
{
  /*color: var(--primary-color)*/
  color: var(--grey-blue)
}

.price-list .book-size .feather.feather-plus-circle,
.price-list td.book-counter .feather.feather-plus-circle,
.price-list td.book-layers .feather.feather-plus-circle
{
  margin-left: 1rem;
}

.price-list td h3 {
  margin-bottom: 0.5rem;
}

.price-list td p {
  padding: 0;
  margin: 0;
}

td.book-counter:before {
  content: "\f5db";
}
td.book-layers:before {
  content: "\e323";
}

.two-lists {
  position: relative;
  margin-bottom: 1.5rem;
}

.calculator-banner {
  margin-top: 1rem;
  position: relative;
  cursor: pointer;
  padding: 2rem 2rem 2rem 10.5rem;
  /*padding: 2rem 2rem 1rem 2rem;*/
  transition: var(--speed);
  display: flex;
  align-items: center;
}

.calculator-banner div {
  float: left;
}

.calculator-banner img {
  width: 4rem;
  margin-right: 2rem;
  /*position: absolute;*/
  /*left: 2rem;*/
  /*top: 3rem;*/
}

.calculator-banner h2 {
  font-size: 2rem;
  margin: 0;
}

.calculator-banner p {
  margin: 0;
  padding: 1rem 0 0;
}

.calculator-banner:before {
  top: 1.5rem;
  left: 3rem;
  content: "\f1ec";
  font-size: 6rem;
  color: var(--classic-grey);
  transition: var(--speed);
}

.calculator-banner.certificate-banner:before {
  top: 2rem;
  content: "\f06b";
  font-size: 5rem;
}

.calculator-banner:hover:before {
  color: var(--white-blue);
}

.colors2 div:hover {
  transition: var(--speed);
  box-shadow:  0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.04);
}

.calculator-result .info:before {
    font-family: FontAwesome;
    font-weight: 200;
    content: "\f00c";
    padding-right: 1rem;
    color: var(--grey-blue);
    opacity: 0.8;
    /*color: var(--semi-grey);*/
}

.calculator-result .info.colors-list:before {
  content: "\e2df";
}

.calculator-result .info.props:before {
  content: "\f77d";
}

.calculator-result .info.books:before {
  /*content: "\f5d9";*/
  content: "\f518";
}

.calculator-result .info.alarm:before {
  content: "\f06a";
  color: var(--dark-alarm)
}
.calculator-result .info.alarm {
  padding-left: 0;
}


.calculator-result .info.colors-list.color1:before {
  color: #cbcbcb;
}

.calculator-result .info.colors-list.color2:before {
  color: #dad1b9;
}

.calculator-result .info.colors-list.color3:before {
  color: #e89ba8;
}

.calculator-result .info.colors-list.color4:before {
  color: #978dda;
}

.calculator-result .info.colors-list.color5:before {
  color: #85b0c5;
}

.calculator-result .info.colors-list.color6:before {
  color: #b2d7ab;
}

.calculator-result .info.colors-list.color7:before {
  color: #cbbf58;
}

.calculator-result .info.colors-list.color8:before {
  color: #9b6f55;
}

.calculator-result .info.colors-list.color9:before {
  color: var(--dark-alarm);
}

.calculator-result .info.colors-list.color10:before {
  color: #297498;
}

.calculator-result .info.colors-list.color11:before {
  color: var(--darker-green);
}

.calculator-result .info.colors-list.color12:before {
  color: #4a377a;
}

.calculator-result div.bolder {
  padding: 0.5rem 0;
}

.calculator-result div.bolder:first-child {
  padding-top: 0;
}

.calculator-result div.bolder:last-child {
  padding-bottom: 0;
}

/*.calculator-result .info .feather {*/
/*  margin-bottom: -0.3rem;*/
/*}*/

.calc div {
  position: relative;
  cursor: pointer;
  margin-top: 1rem;
  transition: var(--speed);
}

.calc:not(.books) div .feather {
  position: absolute;
  top: 0;
  left: 0;
  transition: var(--speed);
}

.calc.books div .feather {
  position: absolute;
  right: 0;
  /*right: 3rem;*/
  transition: var(--speed);
}
.calc.books div .feather:first-child {
  /*left: 0*/
  right: 3rem;
}

/*.calc .mini-title {*/
/*}*/

.circle .mini-title, .circle .feather.feather-circle {
  color: var(--dark-color);
}

.circle .feather.feather-check-circle, .check-circle .mini-title, .circle:hover .mini-title, .circle:hover .mini-title .feather {
  color: var(--black);
}

/*.circle2233 .mini-title:hover .feather.feather-circle,*/
.circle .feather.feather-check-circle, .check-circle .feather.feather-circle {
  display: none;
}

/*.circle2233 .mini-title:hover .feather.feather-check-circle,*/
.circle .feather.feather-circle, .check-circle .feather.feather-check-circle {
  display: block;
}


.calc div .feather.feather-check-circle {
}

.calc div .mini-title {
  margin-bottom: 0.5rem;
}

.calc:not(.books) div .mini-title {
  padding-left: 3rem;
}

.calc img, .home-box img {
  border-radius: 5px;
  width: 100%;
  opacity: 0.9;
}

.home-box div:hover img {
  opacity: 1;
}

.circle:hover img, .check-circle img {
  opacity: 1;
}

.baby_data.hide {
  display: none;
}

.baby_data {
  grid-template-columns: 1fr 1fr;
  width: 50%;
}

.baby_data input {
  width: 100%;
}

.images-box.booking p {
  padding-bottom: 0;
  margin-bottom: 0;
}

.container.no-top.after-price-list {
  margin-top: -1rem;
}

a.bolder {
  color: var(--light-black)
}

a.bolder:hover {
  color: var(--black)
}

.price-list div, .calculator-list div.price-item, .calculator-list div.calculator-result {
  padding-top: 2rem;
  background: #fff;
  border: 1px solid var(--white-blue);
  border-radius: 1rem;
  cursor: pointer;
  transition: var(--speed);
  color: var(--grey-blue);
  margin-bottom: 2rem;
}

div.price-item {
  cursor: auto;
}

/*.calculator-list div.price-item, */
.calculator-list div.calculator-result {
  padding: 2rem;
}

.price-list div:hover ul, .calculator-list div.price-item:hover ul {
  border-top: 1px solid var(--light-color);
}

.price-list div:hover,
.calculator-list div.price-item:hover,
.calculator-list div.calculator-result:hover {
  border: 1px solid var(--light-color);
  color: var(--primary-color)
}

.price-list div ul, .calculator-list div.price-item ul {
  list-style: circle;
  border-top: 1px solid var(--white-blue);
  padding: 2rem 3rem 0 4rem;
  margin-bottom: 2rem;
}

.circle-ul ul {
  padding-left: 1.5rem;
  list-style: circle;
  margin-top: 0;
  margin-bottom: 1.5rem;
}

.price-list div li, .calculator-list div.price-item li, .circle-ul li {
  display: list-item;
  padding-bottom: 0.5rem;
  font-size: 1.4rem;
}
.price-list .feather, .price-list img.fontawesome,
.calculator-list div.price-item .feather,
.calculator-list div.price-item img.fontawesome {
  text-align: center;
  width: 4rem;
  height: 4rem;
  color: var(--light-color);
  margin: 0.5rem 0;
  fill: #ffffff;
}

.price-list img.fontawesome.camera-mini,
.calculator-list div.price-item img.fontawesome.camera-mini {
  width: 3rem;
  height: 3rem;
  margin: 1.3rem 0 0.7rem;
}

.price-list h2,
.calculator-list div.price-item h2
{
  color: #373844;
  text-align: center;
  margin-bottom: 0.3rem;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 500;
}

.price-list h3,
.calculator-list div.price-item h3
{
  text-align: center;
  font-size: 1.2rem;
  font-weight: 100;
  /*color: var(--light-color);*/
  color: var(--grey-blue);
}

.price-list h5,
.calculator-list div.price-item h5 {
  color: var(--white-blue);
  font-size: 3rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 1rem;
  transition: var(--speed);
  padding-bottom: 1rem;
}

.price-list div:hover h3,
.calculator-list div.price-item div:hover h3
{
  /*color: var(--light-color);*/
  color: var(--primary-color);
}

.price-list h6,
.calculator-list div.price-item h6
{
  color: var(--grey-blue);
  font-size: 1.5rem;
  font-weight: 400;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;
}

.price-list table {
  width: 100%;
  position: relative;
}

.price-list table tr:first-child td {
  padding-top: 4rem;
}

.price-list table tr td {
  width: 33.33334%;
  position: relative;
}

.price-list table tr td h3 {
  word-spacing: 100vw;
}
.price-list table .feather {
  width: 5rem;
  height: 5rem;
}

.gallery-fixed-block.gallery-navigation  {
  position: fixed;
  right: 0;
  top: 40rem;
  z-index: 3;
  padding: 1rem;
  background-color:rgba(255,255,255,0.5);
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
}
.gallery-fixed-block.gallery-navigation div {
  padding: 0;
}

.response {
  text-align: center;
  width: 50%;
}

.response .feather, .response img {
  margin: 1rem auto;
  width: 5rem;
  height: 5rem;
}

.response h3 {
  font-weight: 400;
  font-size: 2rem;
}

.response p {
  text-align: justify;
}

.certificates-form {
  margin: 0 auto;
  max-width: 400px;
  position: relative;
}

.certificates-form input[type="text"], .certificates-form .custom_select{
  width: 100%;
}

.more-information, .certificates-list {
  max-width: 768px;
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 2rem;
  justify-content: space-between;
  grid-auto-rows: 1fr;
  justify-items: center;
  align-items: center;
  transition: var(--speed);
  border-radius: 1rem;
}
.certificates-list {
  margin: 0 auto;
}

.certificates-list img {
  padding-bottom: 1rem;
}


.more-information {
  cursor: pointer;
  margin: 4rem auto 0;
  padding: 4rem;
}

.more-information.photo-day-information {
  cursor: auto;
}

.more-information div h5 {
  font-weight: 300;
  font-size: 2rem;
  color: var(--easy-black)
}

.more-information div p {
  color: var(--grey-blue);
  margin-top: 1rem;
}

.more-information div img {
  border-radius: 50%;
  transition: var(--speed);
}
.more-information div img, .react-loading-skeleton.more-info-circle {
  height: 26rem;
  width: 26rem;
}

img.no-border-radius {
  border-radius: 0;
}

.more-information:hover:not(.photo-day-information) {
  background: #f6f6f6;
}

.more-information:hover:not(.photo-day-information) img {
  opacity: 90%;
}

.relative {
  position: relative;
}

.banner {
  position: absolute;
  float: right;
  top: 3rem;
  max-width: 12rem;
  text-align: center;
  right: 0;
  background: var(--light-native);
  opacity: 0.8;
  padding: 1rem 2rem;
  font-size: 1.1rem;
  text-transform: uppercase;
  color: var(--white);
}

.no-thumbnail {
  background: #ededed;
  width: 26rem;
  height: 13rem;
  border-radius: 50%;
  text-align: center;
  padding-top: 13rem;
  font-size: 0.9rem;
  color: var(--grey-blue);
}


.footer {
  clear: both;
  position: relative;
  width: 100%;
  border-top: 1px solid #ededed;
}

.footer-contacts {
  /*width: 32rem;*/
  text-align: left;
}

.footer-contacts div {
  /*font-size: 1.5rem;*/
  position: relative;
  padding: 0 0 0 3rem;
  margin-bottom: 0.2rem;
}

.footer-contacts div .feather {
  opacity: 20%;
  position: absolute;
  top: 0.2rem;
  left: 0;
}

.footer-container {
  max-width: var(--content-width);
  padding: var(--big-padding);
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
}

.footer .right-align {
  text-align: right;
}

h4 {
  display: flex;
  flex-direction: row;
  color: #626262;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 100;
  margin: 0 auto;
  letter-spacing: 0.15rem;
}

h4:before,
h4:after {
  content: "";
  flex: 1 1;
  border-bottom: 1px solid var(--light-color);
  margin: auto;
}

h4:before {
  margin-right: 1rem;
}
h4:after {
  margin-left: 1rem;
}

.title-line {
  margin: 0 auto;
}

.title-line li:last-child {
  position: absolute;
  right: 0;
  padding-right: 0;
}

.title-image {
  position: relative;
}

ul.items-list {
  padding-top: 1rem;
  margin-bottom: 1rem;
}

ul.items-list li {
  display: block;
  list-style-type: circle;
  padding-bottom: 0.5rem;
  font-size: 1.4rem;
}

ul.items-list li:before {
  content: "•";
  padding-right: 1rem;
  color: var(--primary-color);
}

.list {
  position: relative;
}

.photo-day-phone {
  position: absolute;
  text-align: right;
  top: 2rem;
  right: 2rem;
}

.photo-day {
  position: relative;
  background: #f8f8f8;
  cursor: pointer;
  width: calc(100% - 8.5rem);
  margin-top: 1rem;
  padding: 1.5rem 1.5rem 1.5rem 7rem;
  border-radius: 1rem;
  transition: var(--speed);
}

.photo-day:hover, .photo-day.active {
  background: #f5f6fa;
}

.photo-day.active {
  cursor: auto;
}

.photo-day .feather {
  width: 3.5rem;
  height: 3.5rem;
  cursor: auto;
  color: #d9d9d9;
  position: absolute;
  top: 2rem;
  left: 2rem;
}

.title-image img {
  margin-top: 1rem;
  min-height: 160px;
  object-fit: cover;
}

.image-hash {
}

.image-hash canvas {
  margin-top: 1rem;
  width: 100%;
  min-height: 160px;
}

.title-image .button {
  text-transform: uppercase;
  font-size: 1rem;
  position: absolute;
  bottom: 4rem;
  right: 3.5rem;
  color: var(--white);
  border: 0;
  transition: var(--speed);
  padding: 0.8rem 1.5rem 0.8rem 1.3rem;
  background: var(--light-native);
}

.title-image .button:before {
  font-family: FontAwesome;
  font-weight: 200;
  content: "\2b";
  padding-right: 1rem;
  color: var(--white);
  z-index: 122;
  opacity: 0.8;
}

.title-image .button.heart:before {
  content: "\f004";
}

.title-image .button:hover:before {
  opacity: 1;
}

.title-image .button:hover {
  background: var(--native);
}
.slick-track {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 10rem;
  justify-content: space-between;
  grid-auto-rows: 1fr;
}

.content-white {
  margin-top: 1rem;
}

.content-white h3 {
  font-weight: 400;
  padding-top: 3rem;
  text-align: center;
  margin-bottom: 0.5rem;
}

.content-white span {
  text-align: center;
  color: var(--grey-blue);
}
.mobile {
  display: none;
}


button {
  width: 100%;
  background: #51a443;
  color: #fff;
  border: 0;
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
  margin-bottom: 5px;
}
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  /*height: 0; */
  overflow: hidden;
  max-width: 100%;
  height: auto;
}
.embed-container iframe, .embed-container object, .embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.image-blur {
  transition:.6s opacity;
}

.image-blur-bg {
  border-radius: var(--border-radius);
  width: 100%;
}

.image-blur-bg.color {
  background: #f7f7f7;
}

.image-blur canvas, .image-blur img {
  border-radius: var(--border-radius);
}

.image-blur img {
  position: absolute;
  top: 0;
  left: 0;
}

.image-show {
  opacity: 1;
}

.image-hide {
  opacity:0;
  width: 0;
  height: 0;
}

.selected-photo-navigation {
  position: relative;
  height: 4rem;
  text-align: right;
  padding-right: 2rem;
  transition: var(--speed);
}

.selected-photo-navigation .feather {
  width: 2.2rem;
  height: 2.2rem;
  margin-right: 2rem;
  transition: var(--speed);
}
.selected-photo-navigation .feather.feather-heart.active {
  fill: var(--black);
  transition: var(--speed);
}

.selected-photo-main {
  margin: 0 auto;
  width: calc(100vh - 10rem);
  max-width: calc(100vw - 10rem);
}

.image-gallery-left-nav, .image-gallery-right-nav {
  color: #fff;
  font-family: FontAwesome;
  font-weight: 200;
  content: "\f00c";
  width: 1rem;
}

.button-small-label {
  color: var(--primary-color);
  font-size: 1.25rem;
}

.no-photos-yet {
  color: var(--grey-blue)
}

.no-photos-yet .feather {
  display: block;
  margin: 7rem 0 5rem;
  color: #ececec;
  width: 15rem;
  height: 15rem;
}

/* Extra small devices (phones, 576px and down) */
/*@media only screen and (max-width: 576px) {...}*/

/* Small devices (portrait tablets and large phones, 576px and up) */
/*@media only screen and (min-width: 576px) {...}*/

/* Medium devices (landscape tablets, 768px and up) */
/*@media only screen and (min-width: 768px) {...}*/

/* Large devices (laptops/desktops, 992px and up) */
/*@media only screen and (min-width: 992px) {...}*/

/* Extra large devices (large laptops and desktops, 1200px and up) */
/*@media only screen and (min-width: 1200px) {...}*/

@media (max-width: 1200px) {

}

@media (max-width: 992px) {
  .more-information div img, .react-loading-skeleton.more-info-circle {
    width: 20rem;
    height: 20rem;
  }

  .colors div  {
    width: 4rem;
    height: 4rem;
  }

  .colors div:hover:before, .colors div.active:before {
    top: 1rem;
    left: 1.5rem;
  }

  .calculator-banner:before {
    top: 2.5rem;
  }
  .calculator-banner.certificate-banner:before {
    top: 3rem;
  }

}

@media (max-width: 810px) {
  .gallery-title {
    padding-top: 2rem;
  }
}

@media (max-width: 768px) {

  .colors {
    grid-template-columns: repeat(6, 1fr);
  }

  .colors div {
    width: 5rem;
    height: 5rem;
  }

  .colors div:hover:before, .colors div.active:before {
    top: 1.5rem;
    left: 2rem;
  }

  .custom_select, .baby_data {
    width: 100%;
  }

  .price-list, .mobile {
    display: block;
  }

  .no-mobile {
    display: none;
  }

  .main_menu li, .horizontal_menu li, .title li, .title-line li {
    padding-right: 1.5rem;
  }

  .main_menu li::before, .horizontal_menu li::before, .title li::before {
    padding-right: 1.5rem;
  }

  input[type="text"], input[type="date"], input[type="number"], textarea {
    width: 100%;
  }
  .more-information div img, .react-loading-skeleton.more-info-circle {
    width: 18rem;
    height: 18rem;
  }

  .calc {
    grid-template-columns: repeat(2, 1fr);
  }

  .calc.books {
    display: block;
  }

  .calc.books .book-content {
    margin-bottom: 4rem;
  }

}

@media (max-width: 576px) {

  .colors {
    grid-template-columns: repeat(4, 1fr);
  }

  .response {
    width: 100%;
  }

  .horizontal_menu li {
    padding-right: 1rem;
  }

  .horizontal_menu li::before {
    content: ' \B7 ';
    padding-right: 1rem;
  }

  /*, .certificates-list*/
  .mobile-only {
    display: block;
  }

  .mobile-only img {
    width: 100%;
  }

  .images-box, .home-box, .instagram-box {
    grid-template-columns: repeat(2, 1fr);
  }

  .images-box.booking, .calculator-list, .new-review, .two-lists {
    display: block;
  }

  .images-box img:nth-child(3), .images-box span:nth-child(3) {
    display: none;
  }

  div.main_menu, .top_navigation, .top_navigation ul, .top_navigation li  {
    margin: 0 auto;
  }

  .gallery-navigation {
    top: -2rem;
  }
  .main_menu .feather {
    display: block;
    margin: 0 auto;
  }

  .main_menu ul:not(.open) {
    opacity: 0;
    visibility: hidden;
    height: 0;
    transition: opacity var(--speed) ease;
  }

  .main_menu ul.open {
    /*width: 100%;*/
    padding: 0;
    margin-top: 1rem;
  }

  .main_menu ul li, .horizontal_menu ul li {
    display: block;
    padding: 0.5rem;
  }

  .main_menu ul.open li::before, .horizontal_menu ul.open li::before {
    content: none;
  }

  .logo {
    top: 1rem;
    margin-bottom: 1rem;
    /*width: 34rem;*/
  }

  .main {
    margin-top: 17rem;
  }

  .more-information {
    display: block;
    margin: 6rem auto;
    align-items: normal;
    /*padding: 0;*/
    padding: 0.5rem 2rem;
  }

  .more-information div:first-child {
    text-align: center;
  }

  .more-information div {
    margin: 3rem auto;
  }

  .more-information div img, .react-loading-skeleton.more-info-circle {
    width: 23rem;
    height: 23rem;
  }

  .certificates-form {
    max-width: 576px;
  }


  .instagram-box img {
    margin-bottom: 2rem;
  }

  .instagram-box a:nth-child(4) {
    display: inline-block;
  }

}
@media (max-width: 446px)
{

  .horizontal_menu a {
    font-size: 1.3rem;
  }

}

.horizontal_menu li:first-child
{
  padding-left: 0;
}

.horizontal_menu li:last-child
{
  padding-right: 0;
}

/*@media (hover: hover) {*/
@media not all and (pointer: coarse) {

  .circle .mini-title:hover .feather.feather-circle {
    display: none;
  }

  .circle .mini-title:hover .feather.feather-check-circle {
    display: block;
  }

  .colors div:hover {
    transition: var(--speed);
    box-shadow:  0 0 0.5rem 0.5rem rgba(0, 0, 0, 0.04);
  }

  .colors div:hover:before
  {
    font-family: FontAwesome;
    font-weight: 200;
    content: "\f00c";
    position: absolute;
    top: 1.5rem;
    left: 2rem;
  }

  .gallery-photo .feather {
    opacity: 0;
    visibility: hidden;
  }
}